import { useAnalytics } from "@components/analytics";
import { useEffect } from "react";
import dayjs from "dayjs";
import { useRouter } from "next/router";

const useTrackReadProgress = () => {
  return;
  const { trackEvent } = useAnalytics();

  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeStart", (url, { shallow }) => {
      console.log("change route", url, shallow);
    });
    router.events.on("beforeHistoryChange", (url, { shallow }) => {
      console.log("change history", url, shallow);
    });
    // taken from https://github.com/plausible/analytics/discussions/121#discussioncomment-2198451
    let scrollDepth = 0;
    const startTime = dayjs();
    let endTime = startTime;
    const scrollListener = function (event: Event) {
      endTime = dayjs();

      // Called on scroll event
      const scrollPosition = (event.currentTarget as Window).scrollY;
      const maxScrollHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;

      if (maxScrollHeight == 0) {
        // Page too short to scroll
        return;
      }

      let scrollPercentage = Math.round(
        (scrollPosition / maxScrollHeight) * 100
      );
      const scrollStep = 10; // Notify new depth each step
      scrollPercentage = scrollPercentage - (scrollPercentage % scrollStep);

      if (scrollDepth < scrollPercentage) {
        scrollDepth = scrollPercentage;
      }
    };

    window.addEventListener("scroll", scrollListener);
    return () => {
      console.log("unmount");
      window.removeEventListener("scroll", scrollListener);
      trackEvent("Scroll", "Final Read Progress", {
        depth: scrollDepth,
        readTime: startTime.diff(endTime, "minutes"),
      });
    };
  }, []);
};

export default useTrackReadProgress;
