type UseAnalyticsReturnType = {
  trackEvent: (
    category: string,
    name: string,
    params?: Record<string, unknown>
  ) => void;
  trackPlausibleEvent: (name: string, params?: Record<string, unknown>) => void;
  trackPiwikEvent: (params: {
    category: string;
    action: string;
    name?: unknown;
    value?: unknown;
    dimensions?: unknown;
  }) => void;
};

function trackPlausibleEvent(name: string, params?: Record<string, unknown>) {
  if (!window.plausible) {
    window.plausible = function (...args: any) {
      (window.q = window.q || []).push(...args);
    };
  }
  window.plausible(name, { props: params });
}

function trackPiwikEvent({
  category,
  action,
  name,
  value,
  dimensions,
}: {
  category: string;
  action: string;
  name?: unknown;
  value?: unknown;
  dimensions?: unknown;
}) {
  if (!window._paq) {
    window._paq = [];
  }
  window._paq.push(["trackEvent", category, action, name, value, dimensions]);
}

function trackEvent(
  category: string,
  name: string,
  params?: Record<string, unknown>
): void {
  trackPlausibleEvent(name, params);
  trackPiwikEvent({ category, action: name, dimensions: params });
}

export default function useAnalytics(): UseAnalyticsReturnType {
  return { trackEvent, trackPlausibleEvent, trackPiwikEvent };
}
